import React from 'react';
import PurchaseVerify from './PurchaseVerify';
import Layout from '../../components/Layout';

export default {

    path: '/purchase-verify',

    async action({ user }) {
        if (!user) {
            return false;
        }

        return {
            title: 'Purchase Verify',
            component: <Layout><PurchaseVerify /></Layout>,
        };
    },

};
