import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import Chart from '../Chart';

const roundTo = require('round-to');

// https://github.com/gf3/moment-range
const moment = extendMoment(Moment);

class ChartEarningsByYear extends React.Component {
    static propTypes = {
        item_id: PropTypes.string,
        height: PropTypes.string,
    };

    static contextTypes = {
        store: PropTypes.shape(),
    };

    shouldComponentUpdate() {
        const self = this;
        const {
            statement,
            settings,
        } = self.context.store.getState();

        const curHash = statement.length + (statement[0] ? statement[0].uniq_id : '') + settings.consider_collaboration;

        if (self.updateHash && self.updateHash === curHash) {
            return false;
        }

        self.updateHash = curHash;
        return true;
    }

    render() {
        const self = this;
        const {
            item_id,
            height = '300px',
        } = self.props;
        const {
            statement,
            settings,
        } = self.context.store.getState();
        const chartsSettings = settings.charts;

        let Years = [];
        const dataSetEarns = [];
        const dataSetSales = [];
        const dataSetReferrals = [];
        const dataSetRefunds = [];
        const dataSetReversals = [];

        // find the first year and add default values
        let firstEarning = false;
        statement.forEach((item) => {
            const thisTime = new Date(parseInt(item.date, 10));

            if (firstEarning) {
                firstEarning = firstEarning > thisTime ? thisTime : firstEarning;
            } else {
                firstEarning = thisTime;
            }
        });

        // generate years array
        if (firstEarning) {
            Years = moment.range(moment(firstEarning).tz('Australia/Sydney').format('YYYY'), moment(new Date()).tz('Australia/Sydney').format('YYYY'));
            Years = Years.snapTo('year');
            Years = Array.from(Years.by('year'));
            Years = Years.map(m => m.format('YYYY'));
        }

        // add zero values by default.
        // for some reason if we add zeroes when statement is not loaded, echart will render some shit...
        if (statement.length) {
            for (let k = 0; k < Years.length; k++) {
                dataSetEarns.push(0);
                dataSetSales.push(0);
                dataSetReferrals.push(0);
                dataSetRefunds.push(0);
                dataSetReversals.push(0);
            }
        }

        // add prices and labels in array
        statement.forEach((item) => {
            // prevent some item types
            if (
                item.type === 'Payout'
                || item.type === 'Withdrawal Request'
                || item.type === 'Withdrawal Cancellation'
                || item.type === 'Withdrawal Rejection'
                || item.type === 'Purchase'
                || item.type === 'Refund'
                || !item.country
            ) {
                return;
            }

            // limit to product id.
            if (item_id && item_id !== item.product_id) {
                return;
            }

            const formatDate = moment(new Date(parseInt(item.date, 10))).tz('Australia/Sydney').format('YYYY');

            const curYear = Years.indexOf(formatDate);
            if (curYear > -1) {
                dataSetEarns[curYear] = (dataSetEarns[curYear] || 0) + item.final_price;

                if (item.type === 'Sale') {
                    dataSetSales[curYear] = (dataSetSales[curYear] ? dataSetSales[curYear] : 0) + 1;
                }
                if (item.type === 'Referral Cut') {
                    dataSetReferrals[curYear] = (dataSetReferrals[curYear] ? dataSetReferrals[curYear] : 0) + 1;
                }
                if (item.type === 'Sale Reversal') {
                    dataSetReversals[curYear] = (dataSetReversals[curYear] ? dataSetReversals[curYear] : 0) + 1;
                }
                if (item.type === 'Sale Refund') {
                    dataSetRefunds[curYear] = (dataSetRefunds[curYear] ? dataSetRefunds[curYear] : 0) + 1;
                }
            }
        });

        // round
        dataSetEarns.forEach((item, k) => {
            dataSetEarns[k] = roundTo(dataSetEarns[k], 2);
        });

        return (
            <Chart
                height={height}
                width="100%"
                option={{
                    yData: [
                        dataSetEarns,
                        dataSetSales,
                        dataSetReferrals,
                        dataSetRefunds,
                        dataSetReversals,
                    ],
                    xData: Years,
                    start: chartsSettings.yearly.start,
                    end: chartsSettings.yearly.end,
                }}
                config={{
                    onEvents: {
                        datazoom: (data) => {
                            self.context.store.dispatch({
                                type: 'UPDATE_SETTINGS',
                                data: {
                                    charts: {
                                        yearly: {
                                            start: data.start,
                                            end: data.end,
                                        },
                                    },
                                },
                            });
                        },
                    },
                }}
            />
        );
    }
}

export default ChartEarningsByYear;
