import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { lookup } from 'country-data';
import s from './ChartLatestSales.scss';
import EachItem from './EachItem.js';

const roundTo = require('round-to');

// https://github.com/gf3/moment-range
const moment = extendMoment(Moment);

class ChartLatestSales extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    constructor(props) {
        super(props);

        this.state = {
            maxItems: 50,
        };
    }

    render() {
        let { maxItems } = this.state;
        const statement = this.context.store.getState().statement;
        const mobile_show_page = this.context.store.getState().settings.mobile_show_page;

        // add prices in array
        const dataSet = [];
        for (const item of statement) {
            if (!maxItems) {
                break;
            }
            maxItems--;

            const date = moment(new Date(parseInt(item.date, 10))).tz('Australia/Sydney');

            let type = item.type;
            if (/Refund/.test(type)) {
                type = 'Refund';
            } else if (/Reversal/.test(type)) {
                type = 'Reversal';
            } else if (/Referral/.test(type)) {
                type = 'Referral';
            } else if (/Withdrawal/.test(type) || /SWIFT Fee/.test(type)) {
                type = 'Withdrawal';
            }

            let support = 0;
            if (item.full_info && item.full_info.length) {
                item.full_info.forEach((val) => {
                    if (val && val.detail && /\(6 months (included|extended) support\)/.test(val.detail)) {
                        support += 6;
                    }
                });
            }

            dataSet.push({
                type,
                title: item.title,
                thumbnail: item.thumbnail ? item.thumbnail : false,
                price: roundTo(item.final_price, 2),
                real_price: roundTo(item.price, 2),
                my_percent: item.my_part,
                my_own: item.my_own,
                date: date.format('DD MMM YYYY hh:mm a'),
                date_friendly: date.fromNow(),
                country: item.country,
                city: item.city,
                product_id: item.product_id,
                support,
                full_info: item.full_info,
            });
        }

        return (
            <Scrollbars
                className={cx(s.root, mobile_show_page ? s.mobileShowPage : '')}
                autoHide
                universal
            >
                <div className={s.wrap}>
                    {dataSet.map((item, i) => <EachItem key={i} data={item} />)}
                    { statement.length && statement.length > this.state.maxItems ? (
                        <div className={s.loadMore} onClick={() => { this.setState({ maxItems: this.state.maxItems + 50 }); }}>Load More</div>
                    ) : '' }
                </div>
            </Scrollbars>
        );
    }
}

export default withStyles(s)(ChartLatestSales);
