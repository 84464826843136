export default function clientSocket(socket, context) {
    const { dispatch } = context.store;

    // statement load
    socket.on('statement start loading', () => {
        dispatch({ type: 'START_LOADING' });
    });
    socket.on('statement stop loading', () => {
        dispatch({ type: 'STOP_LOADING' });
    });
    socket.on('statement loaded', ({ data }) => {
        dispatch({
            type: 'LOAD_STATEMENT',
            data,
        });
    });
    socket.emit('statement fetch');

    // load products
    const productsIDs = [];
    let productsCount = 0;
    socket.on('statement loaded', ({ data }) => {
        data.forEach((item) => {
            if (item && item.product_id && !productsIDs.includes(item.product_id) && !productsIDs.includes(parseInt(item.product_id, 10))) {
                productsIDs.push(parseInt(item.product_id, 10));
            }
        });
        if (productsCount < productsIDs.length) {
            productsCount = productsIDs.length;
            socket.emit('products fetch', {
                ids: productsIDs,
            });
        }
    });
    socket.on('products loaded', ({ data }) => {
        dispatch({
            type: 'LOAD_PRODUCTS',
            data,
        });
    });
}
