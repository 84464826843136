
export default function products(state = [], action) {
    switch (action.type) {
    case 'LOAD_PRODUCTS':
        if (action.data) {
            if (action.data.length) {
                // sort by date
                action.data.sort((a, b) => new Date(parseInt(b.published_at, 10)).getTime() - new Date(parseInt(a.published_at, 10)).getTime());
            }

            return action.data;
        }
        break;
    // no default
    }

    return state;
}
