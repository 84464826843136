import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Moment from 'moment-timezone';
import { Row, Col } from 'react-flexgrid';
import s from './PurchaseVerify.scss';
import Widget from '../../components/Widget';
import Spinner from '../../components/Spinner';

const roundTo = require('round-to');
const commafy = require('commafy');

class PurchaseVerify extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    constructor(props) {
        super(props);

        this.state = {
            code: '',
            loading: false,
            response: '',
            purchaseData: false,
        };
    }

    submit() {
        const code = this.state.code;

        if (!code) {
            return;
        }
        this.setState({ loading: true });

        const data = {
            code,
        };
        const socket = this.context.store.getState().socket;

        socket.emit('purchase verify', data, ({ data }) => {
            const newState = {
                loading: false,
                response: '',
                purchaseData: false,
            };

            if (data && !data.error) {
                data = data.data;

                data.sold_at = Moment(new Date(data.sold_at)).tz('Australia/Sydney').format('DD MMM YYYY hh:mm a');
                data.supported_until = Moment(new Date(data.supported_until)).tz('Australia/Sydney').format('DD MMM YYYY hh:mm a');
                const isSupported = Moment(new Date(data.supported_until)).tz('Australia/Sydney').isAfter(Moment().tz('Australia/Sydney'));

                newState.response = (
                    <div className={s.response}>
                        <div className={s.buyer}>
                            <small>Buyer</small>
                            <a href={`https://themeforest.net/user/${data.buyer}`} target="_blank">{ data.buyer }</a>
                        </div>
                        <div className={s.license}>
                            <small>License</small>
                            <span>{ data.license }</span>
                        </div>
                        <div className={s.purchase_count}>
                            <small>Count</small>
                            <span>{ data.purchase_count }</span>
                        </div>
                        <div className={s.sold_at}>
                            <small>Sold At</small>
                            <span>{ data.sold_at }</span>
                        </div>
                        <div className={s.support_amount}>
                            <small>Support Amount</small>
                            <span>
$
                                { commafy(roundTo(data.support_amount, 2)) }
                            </span>
                        </div>
                        <div className={s.supported_until}>
                            <small>Supported Until</small>
                            <span className={isSupported ? s.success : s.error}>{ data.supported_until }</span>
                        </div>
                        <div className={s.item}>
                            <small>Item</small>
                            <a href={data.item.url} target="_blank">{ data.item.name }</a>
                        </div>
                    </div>
                );
            } else if (data && data.response) {
                newState.response = <div className={`${s.response} ${s.error}`}><div>{ data.response }</div></div>;
            }

            this.setState(newState);
        });
    }

    render() {
        return (
            <Widget>
                <div className={s.root + (this.state.loading ? ` ${s.loading}` : '')}>
                    <input
                        value={this.state.code}
                        placeholder="Purchase Code"
                        onChange={(e) => {
                            this.setState({ code: e.target.value });
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                this.submit();
                            }
                        }}
                    />
                    <button onClick={(e) => {
                        e.preventDefault();
                        this.submit();
                    }}
                    >
                        { this.state.loading ? <Spinner color="white" size="small" /> : '' }
                        {' '}
    Verify
                    </button>

                    { this.state.response || '' }
                </div>
            </Widget>
        );
    }
}

export default withStyles(s)(PurchaseVerify);
