import fetch from './fetch';

function fetchGraphql(query, user = null) {
    return fetch('/graphql/', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query,
            user,
        }),
        credentials: 'include',
    })
        .then(resp => resp.json())
        .then(resp => (resp && resp.data ? resp.data : false));
}

export default fetchGraphql;
