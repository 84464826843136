import React from 'react';
import PropTypes from 'prop-types';
import Chart from '../Chart';

const roundTo = require('round-to');

class ChartEarningsByProducts extends React.Component {
    static propTypes = {
        height: PropTypes.string,
    };

    static contextTypes = {
        store: PropTypes.shape(),
    };

    shouldComponentUpdate() {
        const self = this;
        const {
            statement,
            settings,
        } = self.context.store.getState();

        const curHash = statement.length + (statement[0] ? statement[0].uniq_id : '') + settings.consider_collaboration;

        if (self.updateHash && self.updateHash === curHash) {
            return false;
        }

        self.updateHash = curHash;
        return true;
    }

    render() {
        const self = this;
        const {
            height = '300px',
        } = self.props;
        const {
            statement,
            settings,
        } = self.context.store.getState();

        const chartsSettings = settings.charts;

        const dataSetEarns = {};
        const dataSetSales = {};
        const dataSetReferrals = {};
        const dataSetRefunds = {};
        const dataSetReversals = {};

        // add prices in array
        statement.forEach((item) => {
            // prevent some item types
            if (
                item.type === 'Payout'
                || item.type === 'Withdrawal Request'
                || item.type === 'Withdrawal Cancellation'
                || item.type === 'Withdrawal Rejection'
                || item.type === 'Purchase'
                || item.type === 'Refund'
                || !item.product_id
            ) {
                return;
            }

            dataSetEarns[item.product_id] = dataSetEarns[item.product_id] || {
                product_id: item.product_id,
                title: item.title,
                price: 0,
            };
            dataSetEarns[item.product_id].price += item.final_price;

            if (item.type === 'Sale') {
                dataSetSales[item.product_id] = (dataSetSales[item.product_id] ? dataSetSales[item.product_id] : 0) + 1;
            }
            if (item.type === 'Referral Cut') {
                dataSetReferrals[item.product_id] = (dataSetReferrals[item.product_id] ? dataSetReferrals[item.product_id] : 0) + 1;
            }
            if (item.type === 'Sale Reversal') {
                dataSetReversals[item.product_id] = (dataSetReversals[item.product_id] ? dataSetReversals[item.product_id] : 0) + 1;
            }
            if (item.type === 'Sale Refund') {
                dataSetRefunds[item.product_id] = (dataSetRefunds[item.product_id] ? dataSetRefunds[item.product_id] : 0) + 1;
            }
        });

        // sort
        const sortDataSet = [];
        Object.keys(dataSetEarns).forEach((k) => {
            sortDataSet.push({
                product_id: dataSetEarns[k].product_id,
                title: dataSetEarns[k].title,
                price: roundTo(dataSetEarns[k].price, 2),
            });
        });
        sortDataSet.sort((a, b) => b.price - a.price);

        // generate labels and data
        const itemsLabels = [];
        const itemsData = [];
        const colors = [
            ['rgba(77, 148, 224, 0.1)', 'rgba(77, 148, 224, 1)'],
            ['rgba(40, 199, 249, 0.1)', 'rgba(40, 199, 249, 1)'],
            ['rgba(30, 232, 181, 0.1)', 'rgba(30, 232, 181, 1)'],
            ['rgba(36, 239, 62, 0.1)', 'rgba(36, 239, 62, 1)'],
            ['rgba(140, 234, 38, 0.1)', 'rgba(140, 234, 38, 1)'],
            ['rgba(243, 230, 19, 0.1)', 'rgba(243, 230, 19, 1)'],
            ['rgba(249, 148, 40, 0.1)', 'rgba(249, 148, 40, 1)'],
            ['rgba(249, 71, 40, 0.1)', 'rgba(249, 71, 40, 1)'],
            ['rgba(249, 40, 173, 0.1)', 'rgba(249, 40, 173, 1)'],
            ['rgba(219, 40, 249, 0.1)', 'rgba(219, 40, 249, 1)'],
            ['rgba(141, 40, 249, 0.1)', 'rgba(141, 40, 249, 1)'],
            ['rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 1)'],
        ];

        const sortDataSetSales = [];
        const sortDataSetReferrals = [];
        const sortDataSetRefunds = [];
        const sortDataSetReversals = [];
        let i = 0;
        sortDataSet.forEach((item) => {
            if (dataSetSales[item.product_id]) {
                sortDataSetSales.push(dataSetSales[item.product_id]);
            }
            if (dataSetReferrals[item.product_id]) {
                sortDataSetReferrals.push(dataSetReferrals[item.product_id]);
            }
            if (dataSetRefunds[item.product_id]) {
                sortDataSetRefunds.push(dataSetRefunds[item.product_id]);
            }
            if (dataSetReversals[item.product_id]) {
                sortDataSetReversals.push(dataSetReversals[item.product_id]);
            }

            itemsLabels.push(item.title);
            itemsData.push({
                value: item.price,
                itemStyle: {
                    normal: {
                        color: colors[i] ? colors[i][0] : colors[colors.length - 1][0],
                        barBorderColor: colors[i] ? colors[i][1] : colors[colors.length - 1][1],
                    },
                },
            });

            i += 1;
        });

        return (
            <Chart
                height={height}
                width="100%"
                option={{
                    type: 'bar',
                    yData: [
                        itemsData,
                        sortDataSetSales,
                        sortDataSetReferrals,
                        sortDataSetRefunds,
                        sortDataSetReversals,
                    ],
                    xData: itemsLabels,
                    start: chartsSettings.items.start,
                    end: chartsSettings.items.end,
                }}
                config={{
                    onEvents: {
                        datazoom: (data) => {
                            self.context.store.dispatch({
                                type: 'UPDATE_SETTINGS',
                                data: {
                                    charts: {
                                        daily: {
                                            start: data.start,
                                            end: data.end,
                                        },
                                    },
                                },
                            });
                        },
                    },
                }}
            />
        );
    }
}

export default ChartEarningsByProducts;
