import React from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Chart.scss';
import { getChartOptions } from '../charts-config';

class Chart extends React.Component {
    static propTypes = {
        option: PropTypes.object,
        config: PropTypes.object,
        height: PropTypes.string,
        width: PropTypes.string,
        minHeight: PropTypes.string,
        maxHeight: PropTypes.string,
    };

    static contextTypes = {
        store: PropTypes.shape(),
    };

    render() {
        const {
            option = {},
            config = {},
            height = '300px',
            width = '100%',
            minHeight = '150px',
            maxHeight = '800px',
        } = this.props;

        config.option = getChartOptions(option);

        return (
            <div
                className={s.root}
                style={{
                    paddingTop: height,
                    width,
                    minHeight,
                    maxHeight,
                }}
            >
                <ReactEcharts
                    className={s.chart}
                    notMerge
                    showLoading={false}
                    lazyUpdate
                    {...config}
                />
            </div>
        );
    }
}

export default withStyles(s)(Chart);
