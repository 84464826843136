import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexgrid';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Widget from '../Widget';
import ChartThisDayMonthYear from '../ChartThisDayMonthYear';
import ChartEarningsByDay from '../ChartEarningsByDay';
import ChartCountries from '../ChartCountries';
import ChartEarningsByYear from '../ChartEarningsByYear';
import ChartEarningsByMonth from '../ChartEarningsByMonth';
import s from './EachItem.scss';

const roundTo = require('round-to');
const commafy = require('commafy');

class ProductsList extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        opened: PropTypes.bool,
    };

    static contextTypes = {
        store: PropTypes.shape(),
    };

    render() {
        const self = this;
        const {
            data = false,
            opened = false,
        } = this.props;

        if (!data) {
            return '';
        }

        const {
            statement,
        } = self.context.store.getState();

        // calculate totals.
        const totals = {
            earns: 0,
            sales: 0,
            refunds: 0,
            reversals: 0,
        };

        // add prices in array
        statement.forEach((item) => {
            // prevent some item types
            if (
                item.type === 'Payout'
                || item.type === 'Withdrawal Request'
                || item.type === 'Withdrawal Cancellation'
                || item.type === 'Withdrawal Rejection'
                || item.type === 'Purchase'
                || item.type === 'Refund'
                || !item.product_id
                || item.product_id != data.item_id
            ) {
                return;
            }

            totals.earns += item.final_price;

            if (item.type === 'Sale') {
                totals.sales += 1;
            }
            if (item.type === 'Sale Reversal') {
                totals.reversals += 1;
            }
            if (item.type === 'Sale Refund') {
                totals.refunds += 1;
            }
        });

        totals.earns = roundTo(totals.earns, 2);
        totals.sales = totals.sales - totals.refunds - totals.reversals;

        return (
            <div className={s.root}>
                <div className={s.thumb}>
                    <img src={data.thumbnail} alt={data.name} />
                </div>
                <div className={s.title}>
                    <span>{data.name}</span>
                    <div className={s.earns}>
                        Earns:&nbsp;
                        <strong>{commafy(totals.earns)}</strong>
                    </div>
                    <div className={s.sales}>
                        Sales:&nbsp;
                        <strong>{commafy(totals.sales)}</strong>
                    </div>
                </div>
                <div className={s.listArrow + (opened ? ` ${s.listArrowUp}` : '')} />
                {opened ? (
                    <div className={s.additional} onClick={(e) => { e.stopPropagation(); }}>
                        <ChartThisDayMonthYear item_id={`${data.item_id}`} />
                        <Row>
                            <Col md={7} lg={8}>
                                <Widget title="Daily earnings">
                                    <ChartEarningsByDay item_id={`${data.item_id}`} height="55%" />
                                </Widget>
                            </Col>
                            <Col md={5} lg={4}>
                                <Widget title="Last month top countries">
                                    <ChartCountries item_id={`${data.item_id}`} height="105%" minHeight="200px" maxHeight="450px" style={{ flex: 1 }} />
                                </Widget>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Widget title="Yearly earnings">
                                    <ChartEarningsByYear item_id={`${data.item_id}`} height="70%" />
                                </Widget>
                            </Col>
                            <Col md={6}>
                                <Widget title="Monthly earnings">
                                    <ChartEarningsByMonth item_id={`${data.item_id}`} height="70%" />
                                </Widget>
                            </Col>
                        </Row>
                    </div>
                ) : ''}
            </div>
        );
    }
}

export default withStyles(s)(ProductsList);
