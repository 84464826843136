import echarts from 'echarts';

const commafy = require('commafy');

const fontFamily = '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

export function getChartOptions({
    type = 'line',
    yData: [dataEarns, dataSales, dataReferrals, dataRefunds, dataReversals],
    xData,
    start,
    end,
}) {
    return {
        tooltip: {
            trigger: 'axis',
            backgroundColor: '#000',
            textStyle: {
                fontFamily,
            },
            formatter(params) {
                let result = `<small>${params[0].name}</small>`;

                params.forEach((param) => {
                    let title = param.seriesName;
                    let value = param.value;
                    if (title === 'Earnings') {
                        title = 'Earn';
                        value = `$${commafy(value)}`;
                    }
                    if (title === 'Sales Number') {
                        title = 'Sales';
                    }

                    result += param.value ? `<br />${title}: <strong>${value}</strong>` : '';
                });

                return result;
            },
            padding: [5, 15, 8, 15],
            transitionDuration: 0,
        },
        grid: {
            left: 0,
            right: 15,
            top: 5,
            containLabel: true,
        },
        calculable: true,
        xAxis: {
            type: 'category',
            boundaryGap: type === 'bar',
            offset: 5,
            data: xData,
            splitLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(0, 0, 0, 0.03)',
                },
            },
            axisLabel: {
                color: '#aaa',
                fontSize: 10,
                fontFamily,
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(0, 0, 0, 0.03)',
                },
            },
        },
        yAxis: [
            {
                type: 'value',
                boundaryGap: [0, '10%'],
                splitLine: {
                    lineStyle: {
                        color: 'rgba(0, 0, 0, 0.03)',
                    },
                },
                axisLabel: {
                    color: '#aaa',
                    fontSize: 10,
                    fontFamily,
                    formatter: '${value}',
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(0, 0, 0, 0.03)',
                    },
                },
            },
            {
                type: 'value',
                boundaryGap: [0, '10%'],
                show: false,
            },
        ],
        dataZoom: [{
            type: 'inside',
            end,
            start,
            minValueSpan: 5, // 5 minimal
            zoomOnMouseWheel: 'ctrl',
            filterMode: 'weakFilter',
        }, {
            start: 70,
            end: 100,
            handleIcon: 'image://data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjY1IiBoZWlnaHQ9IjY2NSIgdmlld0JveD0iMCAwIDY2NSA2NjUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PHRpdGxlPkdyb3VwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHVzaW5nIEZpZ21hPC9kZXNjPjxnIGlkPSJDYW52YXMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMwMSAzNTQpIj48ZyBpZD0iR3JvdXAiPjxnIGlkPSJFbGxpcHNlIj48dXNlIHhsaW5rOmhyZWY9IiNwYXRoMF9maWxsIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzAxIC0zNTQpIiBmaWxsPSIjMjg3MUY5Ii8+PC9nPjxnIGlkPSJSZWN0YW5nbGUiPjx1c2UgeGxpbms6aHJlZj0iI3BhdGgxX2ZpbGwiIHRyYW5zZm9ybT0ibWF0cml4KC00LjM3MTE0ZS0wOCAtMSAxIC00LjM3MTE0ZS0wOCAtNTMgMTUwKSIgZmlsbD0iI0ZGRkZGRiIvPjwvZz48ZyBpZD0iUmVjdGFuZ2xlIj48dXNlIHhsaW5rOmhyZWY9IiNwYXRoMV9maWxsIiB0cmFuc2Zvcm09Im1hdHJpeCgtNC4zNzExNGUtMDggLTEgMSAtNC4zNzExNGUtMDggNTcgMTUwKSIgZmlsbD0iI0ZGRkZGRiIvPjwvZz48L2c+PC9nPjxkZWZzPjxwYXRoIGlkPSJwYXRoMF9maWxsIiBkPSJNIDY2NSAzMzIuNUMgNjY1IDUxNi4xMzUgNTE2LjEzNSA2NjUgMzMyLjUgNjY1QyAxNDguODY1IDY2NSAwIDUxNi4xMzUgMCAzMzIuNUMgMCAxNDguODY1IDE0OC44NjUgMCAzMzIuNSAwQyA1MTYuMTM1IDAgNjY1IDE0OC44NjUgNjY1IDMzMi41WiIvPjxwYXRoIGlkPSJwYXRoMV9maWxsIiBkPSJNIDAgMEwgMzQ0IDBMIDM0NCA2MEwgMCA2MEwgMCAwWiIvPjwvZGVmcz48L3N2Zz4=',
            handleSize: 15,
            // backgroundColor: '#ccc',
            borderColor: 'rgba(0, 0, 0, 0.05)',
            fillerColor: 'rgba(0, 0, 0, 0.05)',
            dataBackground: {
                lineStyle: {
                    width: 1,
                    opacity: 0.2,
                    color: '#000',
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: 'rgba(0, 0, 0, 0)',
                    }, {
                        offset: 1,
                        color: 'rgba(0, 0, 0, 0.2)',
                    }]),
                },
            },
        }],
        series: [
            {
                name: 'Earnings',
                type,
                smooth: true,
                symbol: 'circle',
                smoothMonotone: 'x',
                animation: true,
                animationDuration: 400,
                animationDurationUpdate: 400,
                sampling: 'average',
                itemStyle: {
                    normal: type === 'bar' ? {
                        barBorderColor: 'rgba(77, 148, 224, 1)',
                        barBorderWidth: 2,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(77, 148, 224, 0)',
                        }, {
                            offset: 1,
                            color: 'rgba(77, 148, 224, 0.1)',
                        }]),
                    } : {
                        color: 'rgba(77, 148, 224, 1)',
                    },
                },
                areaStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(77, 148, 224, 0.15)',
                        }, {
                            offset: 1,
                            color: 'rgba(77, 148, 224, 0)',
                        }]),
                    },
                },
                data: dataEarns,
            },
            {
                name: 'Sales Number',
                type: 'line',
                smooth: true,
                symbol: 'none',
                smoothMonotone: 'x',
                animation: true,
                animationDuration: 400,
                animationDurationUpdate: 400,
                sampling: 'average',
                yAxisIndex: 1,
                lineStyle: {
                    width: 1,
                },
                itemStyle: {
                    normal: {
                        color: 'rgba(0, 0, 0, 0.05)',
                    },
                },
                data: dataSales,
            },
            {
                name: 'Referrals',
                type: 'custom',
                data: dataReferrals,
            },
            {
                name: 'Refunds',
                type: 'custom',
                data: dataRefunds,
            },
            {
                name: 'Reversals',
                type: 'custom',
                data: dataReversals,
            },
        ],
    };
}
