import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { Row, Col } from 'react-flexgrid';
import Widget from '../Widget';
import s from './ChartThisDayMonthYear.scss';

const roundTo = require('round-to');
const commafy = require('commafy');

// https://github.com/gf3/moment-range
const moment = extendMoment(Moment);

class ChartThisDayMonthYear extends React.Component {
    static propTypes = {
        item_id: PropTypes.string,
    };

    static contextTypes = {
        store: PropTypes.shape(),
    };

    shouldComponentUpdate(nextProps, nextState) {
        const statement = this.context.store.getState().statement;
        const settings = this.context.store.getState().settings;
        const curHash = statement.length + (statement[0] ? statement[0].uniq_id : '') + settings.consider_collaboration;

        if (this.updateHash && this.updateHash === curHash) {
            return false;
        }
        this.updateHash = curHash;
        return true;
    }

    render() {
        const statement = this.context.store.getState().statement;
        const { item_id } = this.props;

        // generate today, month and year
        const today = moment().tz('Australia/Sydney');
        const thisDay = today.format('DD MMM YYYY');
        const thisMonth = today.format('MMM YYYY');
        const thisYear = today.format('YYYY');

        let thisDayEarnings = 0;
        let thisMonthEarnings = 0;
        let thisYearEarnings = 0;

        let thisDaySales = 0;
        let thisMonthSales = 0;
        let thisYearSales = 0;

        let thisDayReferrals = 0;
        let thisMonthReferrals = 0;
        let thisYearReferrals = 0;

        let thisDayRefunds = 0;
        let thisMonthRefunds = 0;
        let thisYearRefunds = 0;

        let thisDayReversals = 0;
        let thisMonthReversals = 0;
        let thisYearReversals = 0;

        // add prices in array
        for (const item of statement) {
            // prevent some item types
            if (
                item.type === 'Payout'
                || item.type === 'Withdrawal Request'
                || item.type === 'Withdrawal Cancellation'
                || item.type === 'Withdrawal Rejection'
                || item.type === 'Purchase'
                || item.type === 'Refund'
            ) {
                continue;
            }

            // limit to product id.
            if (item_id && item_id !== item.product_id) {
                continue;
            }

            const itemDate = moment(new Date(parseInt(item.date, 10))).tz('Australia/Sydney');

            if (itemDate.format('DD MMM YYYY') === thisDay) {
                thisDayEarnings += item.final_price;
                if (item.type === 'Sale') {
                    thisDaySales += 1;
                }
                if (item.type === 'Referral Cut') {
                    thisDayReferrals += 1;
                }
                if (item.type === 'Sale Reversal') {
                    thisDayReversals += 1;
                }
                if (item.type === 'Sale Refund') {
                    thisDayRefunds += 1;
                }
            }
            if (itemDate.format('MMM YYYY') === thisMonth) {
                thisMonthEarnings += item.final_price;
                if (item.type === 'Sale') {
                    thisMonthSales += 1;
                }
                if (item.type === 'Referral Cut') {
                    thisMonthReferrals += 1;
                }
                if (item.type === 'Sale Reversal') {
                    thisMonthReversals += 1;
                }
                if (item.type === 'Sale Refund') {
                    thisMonthRefunds += 1;
                }
            }
            if (itemDate.format('YYYY') === thisYear) {
                thisYearEarnings += item.final_price;
                if (item.type === 'Sale') {
                    thisYearSales += 1;
                }
                if (item.type === 'Referral Cut') {
                    thisYearReferrals += 1;
                }
                if (item.type === 'Sale Reversal') {
                    thisYearReversals += 1;
                }
                if (item.type === 'Sale Refund') {
                    thisYearRefunds += 1;
                }
            }
        }

        // round and save
        thisDayEarnings = roundTo(thisDayEarnings, 2);
        thisMonthEarnings = roundTo(thisMonthEarnings, 2);
        thisYearEarnings = roundTo(thisYearEarnings, 2);

        return (
            <Row className={s.root}>
                <Col md={4}>
                    <Widget title="Today">
                        <div className={s.body}>
                            <div>
                                <span>Earn</span>
                                {' '}
$
                                {commafy(thisDayEarnings)}
                            </div>
                            <div>
                                <span>Sales</span>
                                {' '}
                                {thisDaySales}
                            </div>
                            <div>
                                <span>Referrals</span>
                                {' '}
                                {thisDayReferrals}
                            </div>
                            <div>
                                <span>Refunds</span>
                                {' '}
                                {thisDayRefunds}
                            </div>
                            <div>
                                <span>Reversals</span>
                                {' '}
                                {thisDayReversals}
                            </div>
                        </div>
                    </Widget>
                </Col>
                <Col md={4}>
                    <Widget title="This Month">
                        <div className={s.body}>
                            <div>
                                <span>Earn</span>
                                {' '}
$
                                {commafy(thisMonthEarnings)}
                            </div>
                            <div>
                                <span>Sales</span>
                                {' '}
                                {thisMonthSales}
                            </div>
                            <div>
                                <span>Referrals</span>
                                {' '}
                                {thisMonthReferrals}
                            </div>
                            <div>
                                <span>Refunds</span>
                                {' '}
                                {thisMonthRefunds}
                            </div>
                            <div>
                                <span>Reversals</span>
                                {' '}
                                {thisMonthReversals}
                            </div>
                        </div>
                    </Widget>
                </Col>
                <Col md={4}>
                    <Widget title="This Year">
                        <div className={s.body}>
                            <div>
                                <span>Earn</span>
                                {' '}
$
                                {commafy(thisYearEarnings)}
                            </div>
                            <div>
                                <span>Sales</span>
                                {' '}
                                {thisYearSales}
                            </div>
                            <div>
                                <span>Referrals</span>
                                {' '}
                                {thisYearReferrals}
                            </div>
                            <div>
                                <span>Refunds</span>
                                {' '}
                                {thisYearRefunds}
                            </div>
                            <div>
                                <span>Reversals</span>
                                {' '}
                                {thisYearReversals}
                            </div>
                        </div>
                    </Widget>
                </Col>
            </Row>
        );
    }
}

export default withStyles(s)(ChartThisDayMonthYear);
