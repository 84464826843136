import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Moment from 'moment-timezone';
import s from './Collaborations.scss';

class PartnersPaymentForm extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    static propTypes = {
        partner: PropTypes.object,
        onSubmit: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            paymentAmount: '',
            paymentDate: null,
            paymentDescription: '',
        };
    }

    submit() {
        let amount = this.state.paymentAmount;
        const partner = this.props.partner;
        const date = this.state.paymentDate;
        const description = this.state.paymentDescription;

        // fix amount
        if (typeof amount === 'string') {
            amount = parseFloat(amount.replace(/,/g, '.'));
        }

        if (!amount || !partner || !date) {
            return;
        }

        const data = {
            amount,
            partner,
            date: date.valueOf(),
            description,
        };
        const socket = this.context.store.getState().socket;

        socket.emit('partner payment create', data, ({ data }) => {
            if (data && !data.error) {
                this.props.onSubmit();
                this.setState({
                    paymentAmount: '',
                    paymentDate: null,
                    paymentDescription: '',
                });
            } else if (data) {
                if (data.response) {
                    alert(data.response);
                } else {
                    console.log(data);
                }
            }
        });
    }

    render() {
        const { partner, onSubmit, ...props } = this.props;

        return (
            <div className={s.paymentForm}>
                <div>
                    <input
                        type="text"
                        value={this.state.paymentAmount}
                        placeholder="Payment Amount *"
                        onChange={(e) => {
                            this.setState({ paymentAmount: e.target.value });
                        }}
                    />
                </div>
                <div>
                    <DatePicker
                        selected={this.state.paymentDate !== '' ? this.state.paymentDate : null}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="DD-MM-YYYY *"
                        maxDate={new Date()}
                        onChange={(date) => {
                            this.setState({ paymentDate: date });
                        }}
                    />
                </div>
                <div className={s.paymentFormTextarea}>
                    <textarea
                        value={this.state.paymentDescription}
                        placeholder="Payment Description"
                        onChange={(e) => {
                            this.setState({ paymentDescription: e.target.value });
                        }}
                    />
                </div>
                <button onClick={(e) => {
                    e.preventDefault();
                    this.submit();
                }}
                >
Pay
                </button>

                <div className={s.paymentFormInfo}>
                        Example: if you paid to your partner, this will be added to summary payment amount.
                </div>
            </div>
        );
    }
}

export default PartnersPaymentForm;
