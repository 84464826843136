import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Spinner.scss';

class Spinner extends React.Component {
    static propTypes = {
        color: PropTypes.string,
        size: PropTypes.string,
    };

    render() {
        let className = s.root;

        if (this.props.color) {
            className += ` ${s[`color-${this.props.color}`]}`;
        }

        if (this.props.size) {
            className += ` ${s[`size-${this.props.size}`]}`;
        }

        return <div className={className}><i /></div>;
    }
}

export default withStyles(s)(Spinner);
