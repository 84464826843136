
let cachedStatement = [];
let considerCollaboration = 'collaboration_percent';
export default function statement(state = [], action) {
    let changedSomething = false;

    switch (action.type) {
    case 'LOAD_STATEMENT':
        if (action.data) {
            const newItems = [];
            action.data.forEach((item) => {
                if (item && item.uniq_id) {
                    let exist = false;

                    cachedStatement.forEach((cachedItem) => {
                        if (!exist && cachedItem.uniq_id === item.uniq_id) {
                            exist = true;
                        }
                    });

                    if (!exist) {
                        newItems.push(item);
                        changedSomething = true;
                    }
                }
            });

            // insert new items in the start of current statement.
            if (newItems.length) {
                cachedStatement = [...newItems, ...cachedStatement];

                // sort by date
                cachedStatement.sort((a, b) => new Date(parseInt(b.date, 10)).getTime() - new Date(parseInt(a.date, 10)).getTime());
            }
        }
        break;

    case 'UPDATE_SETTINGS':
        if (action.data && action.data.consider_collaboration && considerCollaboration !== action.data.consider_collaboration) {
            considerCollaboration = action.data.consider_collaboration;
            changedSomething = true;
        }
        break;
    // no default
    }

    // if nothing changed, return old state.
    if (!changedSomething) {
        return state;
    }

    state = [];

    // prepare statement data consider selected collaboration
    cachedStatement.forEach((cachedItem) => {
        const item = Object.assign({}, cachedItem);
        item.final_price = item.price;

        switch (considerCollaboration) {
        case 'collaboration_percent':
            item.final_price = item.price * item.my_part / 100;
        // fallthrough
        case 'collaboration':
            state.push(item);
            break;
        case 'mine_percent':
            if (item.my_own) {
                item.final_price = item.price * item.my_part / 100;
            }
        // fallthrough
        case 'mine':
            if (item.my_own) {
                state.push(item);
            }
            break;
        // no default
        }
    });

    return state;
}
