const deepAssign = require('deep-assign');

export default function settings(state = {}, action) {
    state = deepAssign({
        // Consider collaboration to show products and prices
        //    Available values:
        //       - 'collaboration_percent' - Show your products + collaboration products with your percent
        //       - 'collaboration' - Show your products + collaboration products with full price
        //       - 'mine_percent' - Show your products with your percent
        //       - 'mine' - Show your products with full price
        consider_collaboration: 'collaboration_percent',

        // Mobile show page
        mobile_show_page: false,

        // Show sales notifications
        sale_notifications: true,

        charts: {
            daily: {
                start: 0,
                end: 100,
            },
            yearly: {
                start: 0,
                end: 100,
            },
            monthly: {
                start: 0,
                end: 100,
            },
            items: {
                start: 0,
                end: 100,
            },
        },
    }, state);

    switch (action.type) {
    case 'UPDATE_SETTINGS':
        if (action.data) {
            state = deepAssign(state, action.data);
        }
        // no default
    }

    return state;
}
