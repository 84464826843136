
export default function socket(state = {}, action) {
    switch (action.type) {
    case 'SOCKET':
        state = action.data;
    // no default
    }

    return state;
}
