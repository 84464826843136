import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Scrollbars } from 'react-custom-scrollbars';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import emoji from 'react-easy-emoji';
import { lookup } from 'country-data';
import s from './ChartCountries.scss';

const roundTo = require('round-to');
const commafy = require('commafy');

// https://github.com/gf3/moment-range
const moment = extendMoment(Moment);

class ChartCountries extends React.Component {
    static propTypes = {
        item_id: PropTypes.string,
        height: PropTypes.string,
        width: PropTypes.string,
        minHeight: PropTypes.string,
        maxHeight: PropTypes.string,
        style: PropTypes.object,
    };

    static contextTypes = {
        store: PropTypes.shape(),
    };

    shouldComponentUpdate() {
        const self = this;
        const {
            statement,
            settings,
        } = self.context.store.getState();

        const curHash = statement.length + (statement[0] ? statement[0].uniq_id : '') + settings.consider_collaboration;

        if (self.updateHash && self.updateHash === curHash) {
            return false;
        }

        self.updateHash = curHash;
        return true;
    }

    render() {
        const self = this;
        const {
            statement,
        } = self.context.store.getState();
        const {
            item_id,
            height = '300px',
            width = '100%',
            minHeight = '150px',
            maxHeight = '800px',
            style = {},
        } = this.props;

        // generate last 30 days array
        let Days = moment.range(moment().tz('Australia/Sydney').subtract(30, 'days'), moment().tz('Australia/Sydney'));
        Days = Array.from(Days.by('day'));
        Days = Days.map(m => m.format('DD MMM YYYY'));

        // add prices in array
        const dataSet = {};
        statement.forEach((item) => {
            // prevent some item types
            if (
                item.type === 'Payout'
                || item.type === 'Withdrawal Request'
                || item.type === 'Withdrawal Cancellation'
                || item.type === 'Withdrawal Rejection'
                || item.type === 'Purchase'
                || item.type === 'Refund'
                || !item.country
            ) {
                return;
            }

            // limit to product id.
            if (item_id && item_id !== item.product_id) {
                return;
            }

            const curDay = Days.indexOf(moment(new Date(parseInt(item.date, 10))).tz('Australia/Sydney').format('DD MMM YYYY'));
            if (curDay > -1) {
                dataSet[item.country] = (dataSet[item.country] || 0) + item.final_price;
            }
        });

        // sort and change to array + round
        const sortDataSet = [];
        Object.keys(dataSet).forEach((item) => {
            sortDataSet.push([item, roundTo(dataSet[item], 2)]);
        });
        sortDataSet.sort((a, b) => (
            b[1] - a[1]
        ));

        return (
            <div
                className={s.root}
                style={{
                    ...{
                        paddingTop: height,
                        width,
                        minHeight,
                        maxHeight,
                    },
                    ...style,
                }}
            >
                <Scrollbars
                    autoHide
                    universal
                >
                    <table>
                        <tbody>
                            {sortDataSet.map((item) => {
                                const countryName = item[0];
                                const country = lookup.countries({ name: countryName })[0];
                                const earnings = `$${commafy(item[1])}`;
                                return (
                                    <tr key={countryName}>
                                        <td className={s.flag}>
                                            {country && country.emoji ? emoji(country.emoji) : ''}
                                        </td>
                                        <td className={s.country}>
                                            {countryName}
                                        </td>
                                        <td className={s.earnings}>
                                            {earnings}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Scrollbars>
            </div>
        );
    }
}

export default withStyles(s)(ChartCountries);
