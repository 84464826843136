exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/*\r\n * Typography\r\n * ======================================================================== */\n/*\r\n * Layout\r\n * ======================================================================== */\n/*\r\n * Media queries breakpoints\r\n * ======================================================================== */\n/* Extra small screen / phone */\n/* Custom Small screen / tablet */\n/* Small screen / tablet */\n/* Medium screen / desktop */\n/* Large screen / wide desktop */\n/*\r\n * Colors\r\n * ======================================================================== */\n._2uDMx {\n  position: relative; }\n._2uDMx > div {\n    position: absolute !important;\n    top: 0;\n    left: 0;\n    width: calc(100% + 20px) !important;\n    height: 100%;\n    margin-right: -10px;\n    padding-right: 20px; }\n._2uDMx > div > div ~ div {\n      will-change: opacity; }\n._2uDMx > div > div ~ div > div {\n      will-change: transform; }\n._2uDMx > div table {\n      width: 100%;\n      margin-left: -3px;\n      margin-top: -10px;\n      margin-bottom: -10px; }\n._2uDMx > div table td {\n        padding: 7px 10px; }\n._2uDMx > div table ._2EhDi {\n        width: 24px;\n        font-size: 25px;\n        padding: 0; }\n._2uDMx > div table ._32_9W {\n        color: #9a9a9a;\n        font-size: 0.8em; }\n._2uDMx > div table ._1ImCd {\n        text-align: right;\n        font-weight: 700;\n        padding-right: 17px; }\n", ""]);

// Exports
exports.locals = {
	"root": "_2uDMx",
	"flag": "_2EhDi",
	"country": "_32_9W",
	"earnings": "_1ImCd"
};