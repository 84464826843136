import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { lookup } from 'country-data';
import emoji from 'react-easy-emoji';
import s from './ChartLatestSales.scss';
import classnames from 'classnames';

const commafy = require('commafy');
const placeholder = require('./placeholder.png');

let test = 1;

class EachItem extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    static propTypes = {
        data: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            hovered: false,
        };
    }

    render() {
        const self = this;
        const { data } = self.props;
        const { hovered } = self.state;
        const { products } = self.context.store.getState();
        let taxes = 0;
        let country = false;
        let countryEmoji = false;
        let region = false;
        let city = false;
        let zipcode = false;

        let thumbnail = placeholder;
        products.forEach((item) => {
            if (`${item.item_id}` === data.product_id) {
                ({ thumbnail } = item);
            }
        });

        const earnings = `$${commafy(data.price)}`;
        let colorClass = '';

        if (data.type === 'Sale') {
            colorClass = s.sale;
        }
        if (data.type === 'Referral') {
            colorClass = s.referral;
        }
        if (data.type === 'Refund' || data.type === 'Reversal') {
            colorClass = s.refund;
        }

        if (data.full_info && data.full_info.length) {
            data.full_info.forEach((val) => {
                if (val) {
                    // taxes
                    if (val.au_gst) {
                        taxes += parseFloat(val.au_gst);
                    }
                    if (val.eu_vat) {
                        taxes += parseFloat(val.eu_vat);
                    }
                    if (val.us_rwt) {
                        taxes += parseFloat(val.us_rwt);
                    }
                    if (val.us_bwt) {
                        taxes += parseFloat(val.us_bwt);
                    }

                    // country
                    if (val.other_party_country) {
                        country = val.other_party_country;

                        const lookupData = lookup.countries({ name: country })[0];
                        countryEmoji = lookupData && lookupData.emoji ? emoji(lookupData.emoji) : '';
                    }
                    if (val.other_party_region) {
                        region = val.other_party_region;
                    }
                    if (val.other_party_city) {
                        city = val.other_party_city;
                    }
                    if (val.other_party_zipcode) {
                        zipcode = val.other_party_zipcode;
                    }
                }
            });
        }

        return (
            <Manager>
                <Reference>
                    {({ ref }) => (
                        <div ref={ref} className={s.each} onMouseEnter={() => self.setState({ hovered: true })} onMouseLeave={() => self.setState({ hovered: false })}>
                            <div className={s.thumbnail}>
                                <img src={thumbnail} alt={data.title} />
                            </div>
                            <div className={s.title}>
                                <h4>{data.title}</h4>
                                <div className={classnames(s.earnings, colorClass)}>
                                    {earnings}
                                </div>
                                <span className={s.date}>
                                    <span>{data.date_friendly}</span>
                                </span>
                            </div>
                        </div>
                    )}
                </Reference>
                { hovered && (
                    ReactDOM.createPortal(
                        <Popper
                            placement="right-start"
                            modifiers={{
                                preventOverflow: {
                                    enabled: true,
                                    boundariesElement: document.body,
                                },
                                hide: { enabled: false },
                                computeStyle: { gpuAcceleration: false },
                            }}
                        >
                            {({
                                ref, style, placement, arrowProps,
                            }) => (
                                <div ref={ref} style={style} className={s.popper}>
                                    <div className={s.popperTitle}>
                                        <small className={colorClass}>{ data.type }</small>
                                        <small>{ data.date }</small>
                                        { data.title }
                                    </div>
                                    <div className={s.popperPrice}>
                                        <div>
                                            <small>{ data.type === 'Refund' || data.type === 'Reversal' ? 'Spent' : 'Earn' }</small>
                                            <span className={colorClass}>{ `$${commafy(data.price)}` }</span>
                                        </div>
                                        <div>
                                            <small>Collaboration</small>
                                            <span>{ data.my_percent !== 100 ? `${data.my_percent}%` : '' }</span>
                                        </div>
                                        <div>
                                            <small>Support</small>
                                            <span className={data.support && data.support > 6 ? s.success : ''}>{ data.support ? `${data.support} months` : '' }</span>
                                        </div>
                                        <div>
                                            <small>Taxes</small>
                                            <span>{ taxes ? `$${commafy(taxes)}` : '' }</span>
                                        </div>
                                    </div>
                                    <div className={s.popperCountry}>
                                        <div>
                                            <small>Country</small>
                                            <span>
                                                { countryEmoji || '' }
                                                { country || '' }
                                            </span>
                                        </div>
                                        <div>
                                            <small>Region</small>
                                            <span>{ region || '' }</span>
                                        </div>
                                        <div>
                                            <small>City</small>
                                            <span>{ city || '' }</span>
                                        </div>
                                        <div>
                                            <small>Zipcode</small>
                                            <span>{ zipcode || '' }</span>
                                        </div>
                                    </div>
                                    <div ref={arrowProps.ref} style={arrowProps.style} data-placement={placement} className={s.popperArrow} />
                                </div>
                            )}
                        </Popper>,
                        document.body,
                    )
                ) }
            </Manager>
        );
    }
}

export default withStyles(s)(EachItem);
