import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-flexgrid';
import s from './Dashboard.scss';
import Widget from '../../components/Widget';
import ChartEarningsByDay from '../../components/ChartEarningsByDay';
import ChartCountries from '../../components/ChartCountries';
import ChartEarningsByMonth from '../../components/ChartEarningsByMonth';
import ChartEarningsByYear from '../../components/ChartEarningsByYear';
import ChartThisDayMonthYear from '../../components/ChartThisDayMonthYear';
import ChartEarningsByProducts from '../../components/ChartEarningsByProducts';

class Dashboard extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    render() {
        const statement = this.context.store.getState().statement;

        return (
            statement.length
                ? (
                    <div className={s.root}>
                        <ChartThisDayMonthYear />
                        <Row>
                            <Col md={7} lg={8}>
                                <Widget title="Daily earnings">
                                    <ChartEarningsByDay height="55%" />
                                </Widget>
                            </Col>
                            <Col md={5} lg={4}>
                                <Widget title="Last month top countries">
                                    <ChartCountries height="105%" minHeight="200px" maxHeight="450px" style={{ flex: 1 }} />
                                </Widget>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Widget title="Yearly earnings">
                                    <ChartEarningsByYear height="70%" />
                                </Widget>
                            </Col>
                            <Col md={6}>
                                <Widget title="Monthly earnings">
                                    <ChartEarningsByMonth height="70%" />
                                </Widget>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <Widget title="Item earnings">
                                    <ChartEarningsByProducts height="40%" />
                                </Widget>
                            </Col>
                        </Row>
                    </div>
                )
                : (
                    <div className={s.empty}>
                    You don't have any statement data yet.
                        <br />
                    Keep app open to fetch your statement.
                    </div>
                )
        );
    }
}

export default withStyles(s)(Dashboard);
