import React from 'react';
import PropTypes from 'prop-types';

const roundTo = require('round-to');
const commafy = require('commafy');

class ChartEarningsByYear extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    shouldComponentUpdate() {
        const self = this;
        const {
            statement,
            settings,
        } = self.context.store.getState();

        const curHash = statement.length + (statement[0] ? statement[0].uniq_id : '') + settings.consider_collaboration;

        if (self.updateHash && self.updateHash === curHash) {
            return false;
        }

        self.updateHash = curHash;
        return true;
    }

    render() {
        const self = this;
        const {
            statement,
        } = self.context.store.getState();

        let balance = 0;

        // add prices and labels in array
        statement.forEach((item) => {
            if (item.my_own) {
                balance += item.price;
            }
        });

        // round
        balance = roundTo(balance, 2);

        // commafy
        balance = commafy(balance);

        return (
            <span>
                $
                {balance}
            </span>
        );
    }
}

export default ChartEarningsByYear;
