import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Moment from 'moment-timezone';
import { Row, Col } from 'react-flexgrid';
import s from './Settings.scss';
import Widget from '../../components/Widget';
import Spinner from '../../components/Spinner';
import SaleNotification from '../../components/SaleNotification';

const roundTo = require('round-to');
const commafy = require('commafy');

const PERMISSION_GRANTED = 'granted';
const PERMISSION_DENIED = 'denied';

const consider_collaboration_list = {
    collaboration_percent: 'Yours + Partners consider your percent',
    collaboration: 'Yours + Partners with full price',
    mine_percent: 'Yours consider your collaboration percent',
    mine: 'Yours with full price',
};

class PurchaseVerify extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    constructor(props) {
        super(props);

        // check from react-web-notification
        // https://github.com/georgeOsdDev/react-web-notification/blob/de352f173e006c6fe95fbf518eb8ddc6f3754afc/lib/components/Notification.js#L55-L69
        let supported = false;
        let granted = false;

        if (typeof window !== 'undefined' && 'Notification' in window && window.Notification) {
            supported = true;

            if (window.Notification.permission === PERMISSION_GRANTED) {
                granted = true;
            }
        }

        this.state = {
            supported,
            granted,
        }; // Do not save Notification instance in state

        this.updateSettings.bind(this);
        this.onPermissionGranted.bind(this);
        this.onPermissionDenied.bind(this);
    }

    updateSettings(val) {
        this.context.store.dispatch({
            type: 'UPDATE_SETTINGS',
            data: val,
        });
    }

    onPermissionGranted() {
        this.setState({
            granted: PERMISSION_GRANTED,
        }, () => {
            this.updateSettings({
                sale_notifications: true,
            });
        });
    }

    onPermissionDenied() {
        this.setState({
            granted: PERMISSION_DENIED,
        });
    }

    render() {
        if (typeof window === 'undefined') {
            return '';
        }
        const settings = this.context.store.getState().settings;

        return (
            <Widget className={s.root}>
                <div className={s.field}>
                    <div className={s.fieldLabel}>
                        Show products:
                    </div>
                    <div className={s.fieldCont}>
                        {Object.keys(consider_collaboration_list).map((key, i) => (
                            <div className={s.fieldRadio} key={i}>
                                <input
                                    id={`settings_${key}_${i}`}
                                    type="radio"
                                    value={key}
                                    checked={key === settings.consider_collaboration}
                                    name="consider_collaboration"
                                    onChange={(e) => {
                                        this.updateSettings({
                                            consider_collaboration: e.target.value,
                                        });
                                    }}
                                />
                                <label htmlFor={`settings_${key}_${i}`}>
                                    {consider_collaboration_list[key]}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={s.field}>
                    <div className={s.fieldCont}>
                        <div className={s.fieldCheckbox}>
                            <input
                                id="settings_sale_notifications"
                                checked={settings.sale_notifications && this.state.granted}
                                type="checkbox"
                                onChange={(e) => {
                                    if (this.state.supported && !this.state.granted) {
                                        window.Notification.requestPermission((permission) => {
                                            const result = permission === PERMISSION_GRANTED;

                                            this.setState({
                                                granted: result,
                                            }, () => {
                                                if (result) {
                                                    this.onPermissionGranted();
                                                } else {
                                                    this.onPermissionDenied();
                                                }
                                            });
                                        });
                                    } else if (this.state.supported) {
                                        this.updateSettings({
                                            sale_notifications: e.target.checked,
                                        });
                                    }
                                }}
                            />
                            <label htmlFor="settings_sale_notifications">
                                Sale Notifications
                            </label>
                        </div>
                    </div>
                </div>
            </Widget>
        );
    }
}

export default withStyles(s)(PurchaseVerify);
