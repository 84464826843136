import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment-timezone';
import s from './Collaborations.scss';
import ProductAdditionalExpensesForm from './ProductAdditionalExpensesForm';

const commafy = require('commafy');

class ProductAdditionalExpenses extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    static propTypes = {
        item: PropTypes.object,
        onSubmit: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            paymentAmount: '',
            paymentDate: null,
        };
    }

    removeAdditionalExpense(item) {
        const removeAdditionalExpense = confirm(`Do you really want to remove additional expense for $${item.amount} on ${Moment(new Date(parseInt(item.date, 10))).tz('Australia/Sydney').format('DD MMM YYYY')}?`);
        const socket = this.context.store.getState().socket;

        if (removeAdditionalExpense) {
            socket.emit('product additional expense remove', item, ({ data }) => {
                if (data && !data.error) {
                    this.props.onSubmit();
                } else if (data) {
                    console.log(data);
                }
            });
        }
    }

    render() {
        const { item, onSubmit, ...props } = this.props;
        const user = this.context.store.getState().user;

        return (
            <div className={s.listPartnersAdditional} onClick={(e) => { e.stopPropagation(); }}>
                <ProductAdditionalExpensesForm product={item.product} onSubmit={onSubmit} />
                {item.additional_expenses && item.additional_expenses.length
                    ? item.additional_expenses.map((item, i) => {
                        const date = Moment(new Date(parseInt(item.date, 10))).tz('Australia/Sydney').format('DD MMM YYYY');
                        const amount = commafy(item.amount);
                        return (
                            <div key={i} className={s.listPartnersAdditionalItem} title={`${item.user.name} paid $${amount}`}>
                                <div className={s.listPartnersAdditionalDate}>{date}</div>
                                <div className={s.listPartnersAdditionalAmount}>
$
                                    {amount}
                                </div>
                                <div className={s.listPartnersAdditionalDescription}>{item.description}</div>
                                <div className={s.listPartnersAdditionalName}>
from
                                    <strong>{item.user.name}</strong>
                                </div>
                                {user.id === item.user.id
                                    ? (
                                        <a
                                            href="#"
                                            className={s.listPartnersAdditionalRemovePayment}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.removeAdditionalExpense(item);
                                            }}
                                        >
Remove Expense
                                        </a>
                                    )
                                    : ''}
                            </div>
                        );
                    })
                    : ''}
            </div>
        );
    }
}

export default ProductAdditionalExpenses;
