import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Moment from 'moment-timezone';
import s from './Collaborations.scss';

class ProductAdditionalExpensesForm extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    static propTypes = {
        product: PropTypes.object,
        onSubmit: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            paymentAmount: '',
            paymentDate: null,
            paymentDescription: '',
        };
    }

    submit() {
        let amount = this.state.paymentAmount;
        const product = this.props.product;
        const date = this.state.paymentDate;
        const description = this.state.paymentDescription;

        // fix amount
        if (typeof amount === 'string') {
            amount = parseFloat(amount.replace(/,/g, '.'));
        }

        if (!amount || !product || !date) {
            return;
        }

        const data = {
            amount,
            product,
            date: date.valueOf(),
            description,
        };
        const socket = this.context.store.getState().socket;

        socket.emit('product additional expense create', data, ({ data }) => {
            if (data && !data.error) {
                this.props.onSubmit();
                this.setState({
                    paymentAmount: '',
                    paymentDate: null,
                    paymentDescription: '',
                });
            } else if (data) {
                if (data.response) {
                    alert(data.response);
                } else {
                    console.log(data);
                }
            }
        });
    }

    render() {
        return (
            <div className={s.paymentForm}>
                <div>
                    <input
                        type="text"
                        value={this.state.paymentAmount}
                        placeholder="Expense Amount *"
                        onChange={(e) => {
                            this.setState({ paymentAmount: e.target.value });
                        }}
                    />
                </div>
                <div>
                    <DatePicker
                        selected={this.state.paymentDate}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="DD-MM-YYYY *"
                        maxDate={new Date()}
                        onChange={(date) => {
                            this.setState({ paymentDate: date });
                        }}
                    />
                </div>
                <div className={s.paymentFormTextarea}>
                    <textarea
                        value={this.state.paymentDescription}
                        placeholder="Expense Description"
                        onChange={(e) => {
                            this.setState({ paymentDescription: e.target.value });
                        }}
                    />
                </div>
                <button onClick={(e) => {
                    e.preventDefault();
                    this.submit();
                }}
                >
Add
                </button>

                <div className={s.paymentFormInfo}>
                Example: if you purchased Visual Composer, you need to add negative amount (-170 or so). Then the part of this amount will be added to Payments for your partners.
                </div>
            </div>
        );
    }
}

export default ProductAdditionalExpensesForm;
