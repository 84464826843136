import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import Chart from '../Chart';

const roundTo = require('round-to');

// https://github.com/gf3/moment-range
const moment = extendMoment(Moment);

class ChartEarningsByDay extends React.Component {
    static propTypes = {
        item_id: PropTypes.string,
        height: PropTypes.string,
    };

    static contextTypes = {
        store: PropTypes.shape(),
    };

    shouldComponentUpdate() {
        const self = this;
        const {
            statement,
            settings,
        } = self.context.store.getState();

        const curHash = statement.length + (statement[0] ? statement[0].uniq_id : '') + settings.consider_collaboration;

        if (self.updateHash && self.updateHash === curHash) {
            return false;
        }

        self.updateHash = curHash;
        return true;
    }

    render() {
        const self = this;
        const {
            item_id,
            height = '300px',
        } = self.props;
        const {
            statement,
            settings,
        } = self.context.store.getState();

        const chartsSettings = settings.charts;

        // generate last 30 days array
        let Days = moment.range(moment().tz('Australia/Sydney').subtract(30, 'days'), moment().tz('Australia/Sydney'));
        Days = Array.from(Days.by('day'));
        Days = Days.map(m => m.format('DD MMM YYYY'));

        // create array with earnings by days
        const dataSetEarns = [];
        const dataSetSales = [];
        const dataSetReferrals = [];
        const dataSetRefunds = [];
        const dataSetReversals = [];

        // add zero values by default.
        // for some reason if we add zeroes when statement is not loaded,
        // echart will render some shit...
        if (statement.length) {
            for (let k = 0; k < Days.length; k += 1) {
                dataSetEarns.push(0);
                dataSetSales.push(0);
                dataSetReferrals.push(0);
                dataSetRefunds.push(0);
                dataSetReversals.push(0);
            }
        }

        // add prices in array
        statement.forEach((item) => {
            // prevent some item types
            if (
                item.type === 'Payout'
                || item.type === 'Withdrawal Request'
                || item.type === 'Withdrawal Cancellation'
                || item.type === 'Withdrawal Rejection'
                || item.type === 'Purchase'
                || item.type === 'Refund'
                || !item.country
            ) {
                return;
            }

            // limit to product id.
            if (item_id && item_id !== item.product_id) {
                return;
            }

            const curDay = Days.indexOf(moment(new Date(parseInt(item.date, 10))).tz('Australia/Sydney').format('DD MMM YYYY'));

            if (curDay > -1) {
                dataSetEarns[curDay] = (dataSetEarns[curDay] ? dataSetEarns[curDay] : 0) + item.final_price;

                if (item.type === 'Sale') {
                    dataSetSales[curDay] = (dataSetSales[curDay] ? dataSetSales[curDay] : 0) + 1;
                }
                if (item.type === 'Referral Cut') {
                    dataSetReferrals[curDay] = (dataSetReferrals[curDay] ? dataSetReferrals[curDay] : 0) + 1;
                }
                if (item.type === 'Sale Reversal') {
                    dataSetReversals[curDay] = (dataSetReversals[curDay] ? dataSetReversals[curDay] : 0) + 1;
                }
                if (item.type === 'Sale Refund') {
                    dataSetRefunds[curDay] = (dataSetRefunds[curDay] ? dataSetRefunds[curDay] : 0) + 1;
                }
            }
        });

        // check if used only 1 year and remove years from x-label
        if (moment(new Date(Days[0])).format('YYYY') === moment(new Date(Days[Days.length - 1])).format('YYYY')) {
            for (let k = 0; k < Days.length; k += 1) {
                Days[k] = moment(new Date(Days[k])).format('DD MMM');
            }
        }

        // round
        dataSetEarns.forEach((item, k) => {
            dataSetEarns[k] = roundTo(dataSetEarns[k], 2);
        });

        return (
            <Chart
                height={height}
                width="100%"
                option={{
                    yData: [
                        dataSetEarns,
                        dataSetSales,
                        dataSetReferrals,
                        dataSetRefunds,
                        dataSetReversals,
                    ],
                    xData: Days,
                    start: chartsSettings.daily.start,
                    end: chartsSettings.daily.end,
                }}
                config={{
                    onEvents: {
                        datazoom: (data) => {
                            self.context.store.dispatch({
                                type: 'UPDATE_SETTINGS',
                                data: {
                                    charts: {
                                        daily: {
                                            start: data.start,
                                            end: data.end,
                                        },
                                    },
                                },
                            });
                        },
                    },
                }}
            />
        );
    }
}

export default ChartEarningsByDay;
