import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import EachItem from './EachItem';
import s from './ProductsList.scss';

class ProductsList extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    constructor(props) {
        super(props);

        this.state = {
            openedIds: {},
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const self = this;
        const { products } = self.context.store.getState();

        if (
            typeof self.productsCount !== 'undefined'
            && self.productsCount === products.length
            && JSON.stringify(self.state.openedIds) === JSON.stringify(nextState.openedIds)
        ) {
            return false;
        }

        self.productsCount = products.length;

        return true;
    }

    render() {
        const self = this;
        const { products } = self.context.store.getState();

        if (!products.length) {
            return 'No products found';
        }

        return (
            <div className={s.root}>
                {products.map((product) => {
                    if (product.mine) {
                        return (
                            <div
                                key={product.item_id}
                                onClick={() => {
                                    self.setState({
                                        openedIds: {
                                            [product.item_id]: !self.state.openedIds[product.item_id],
                                        },
                                    });
                                }}
                            >
                                <EachItem
                                    data={product}
                                    opened={!!(self.state.openedIds[product.item_id] || false)}
                                />
                            </div>
                        );
                    }
                    return '';
                })}
            </div>
        );
    }
}

export default withStyles(s)(ProductsList);
