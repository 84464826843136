import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Widget.scss';

class Widget extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        title: PropTypes.string,
        className: PropTypes.string,
    };

    render() {
        const { title = false } = this.props;

        return (
            <div className={`${this.props.className} ${s.root}`}>
                {title ? <div className={s.title}>{title}</div> : ''}
                <div className={s.content}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default withStyles(s)(Widget);
