/* eslint-disable global-require */
import { combineReducers } from 'redux';

export default combineReducers({
    socket: require('./socket').default,
    path: require('./path').default,
    settings: require('./settings').default,
    user: require('./user').default,
    loading: require('./loading').default,
    statement: require('./statement').default,
    products: require('./products').default,
});
