import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-flexgrid';
import s from './Products.scss';
import Widget from '../../components/Widget';
import ProductsList from '../../components/ProductsList';

class Products extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    render() {
        return (
            <div className={s.root}>
                <Row>
                    <Col xs={12}>
                        <Widget title="Products">
                            <ProductsList />
                        </Widget>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withStyles(s)(Products);
