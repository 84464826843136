import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'react-web-notification';

const roundTo = require('round-to');
const commafy = require('commafy');
const placeholder = require('./icon.png');
const sound_mp3 = require('./sound.mp3');
const sound_ogg = require('./sound.mp3');

class SaleNotification extends React.Component {
    static contextTypes = {
        store: PropTypes.shape(),
    };

    constructor(props) {
        super(props);

        this.state = {
            ignore: true,
            title: '',
            latestSaleDate: '',
        };
    }

    handlePermissionGranted() {
        this.setState({
            ignore: false,
        });
    }

    handlePermissionDenied() {
        this.setState({
            ignore: true,
        });
    }

    handleNotSupported() {
        this.setState({
            ignore: true,
        });
    }

    handleNotificationOnShow() {
        document.getElementById('notification-api-sound').play();
    }

    componentDidUpdate() {
        const self = this;
        if (self.state.ignore) {
            return;
        }
        const {
            statement,
            products,
        } = self.context.store.getState();

        if (!statement.length) {
            return;
        }

        if (!self.state.latestSaleDate) {
            self.setState({
                latestSaleDate: parseInt(statement[0].date, 10),
            });
            return;
        }

        // get latest sales
        let k = 0;
        let price = 0;
        let latestSale = false;
        for (const item of statement) {
            if (item.type !== 'Sale') {
                continue;
            }
            if (parseInt(item.date, 10) === self.state.latestSaleDate) {
                break;
            }
            latestSale = latestSale || item;
            price += item.final_price;
            k++;

            if (k === 100) {
                break;
            }
        }

        let thumbnail = placeholder;
        if (latestSale && latestSale.product_id) {
            products.forEach((item) => {
                if (`${item.item_id}` === latestSale.product_id) {
                    ({ thumbnail } = item);
                }
            });
        }

        // user friendly price
        price = roundTo(price, 2);
        price = `$${commafy(price)}`;

        // generate notification
        let title = '';
        let body = '';
        if (!k) {
            return;
        } if (k === 1) {
            title = 'New Sale!';
            body = `You just sold ${latestSale.title} for ${price}.`;
        } else if (k >= 100) {
            body = 'You just sold 100+ sales.';
        } else {
            title = `${k} New Sales!`;
            body = `You just made ${k} sales for ${price}.`;
        }

        // Available options
        // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
        self.setState({
            latestSaleDate: parseInt(latestSale.date, 10),
            title,
            options: {
                tag: Date.now(),
                body,
                icon: thumbnail,
                lang: 'en',
                dir: 'ltr',
                sound: sound_mp3,
            },
        });
    }

    render() {
        const self = this;
        const { settings } = self.context.store.getState();

        if (typeof window === 'undefined' || !settings.sale_notifications) {
            return <div />;
        }

        return (
            <div>
                <Notification
                    ignore={self.state.ignore && self.state.title !== ''}
                    notSupported={self.handleNotSupported.bind(self)}
                    onPermissionGranted={self.handlePermissionGranted.bind(self)}
                    onPermissionDenied={self.handlePermissionDenied.bind(self)}
                    onShow={self.handleNotificationOnShow.bind(self)}
                    timeout={10000}
                    title={self.state.title}
                    options={self.state.options}
                />
                <audio id="notification-api-sound" preload="auto">
                    <source src={sound_ogg} type="audio/ogg" />
                    <source src={sound_mp3} type="audio/mpeg" />
                </audio>
            </div>
        );
    }
}

export default SaleNotification;
