import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import * as Icon from 'react-feather';
import s from './Navigation.scss';
import Link from '../Link';
import AccountBalance from '../AccountBalance';
import Spinner from '../Spinner';


class Navigation extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static contextTypes = {
        store: PropTypes.shape(),
    };

    mobileShowPageChange(val) {
        const self = this;
        self.context.store.dispatch({
            type: 'UPDATE_SETTINGS',
            data: {
                mobile_show_page: val,
            },
        });
    }

    logoutClick(event) {
        const logout = window.confirm('Do you really want to log out?');

        if (!logout) {
            event.preventDefault();
        }
    }

    render() {
        const self = this;
        const {
            loading,
            user,
            path,
            settings,
        } = self.context.store.getState();

        const { mobile_show_page } = settings;

        return (
            <div>
                <div className={cx(s.root, self.props.className, mobile_show_page ? s.mobileShowPage : '')} role="navigation">
                    <div className={s.left}>
                        <div>
                            <img src={user && user.image} alt={user && user.name} title={user && user.name} className={s.avatar} />
                        </div>
                        <span className={s.balance}>
                            <AccountBalance />
                        </span>
                        {loading ? <Spinner /> : ''}
                        <span
                            className={s.toggleMenu}
                            onClick={() => {
                                self.mobileShowPageChange(!mobile_show_page);
                            }}
                        />
                    </div>
                    <div className={s.right}>
                        <span
                            className={s.toggleMenu}
                            onClick={() => {
                                self.mobileShowPageChange(!mobile_show_page);
                            }}
                        />

                        <ul>
                            <li className={path === '/' ? s.active : ''}>
                                <Link to="/">
                                    <Icon.Home size="18" />
                                    Overview
                                </Link>
                            </li>
                            <li className={path === '/products/' ? s.active : ''}>
                                <Link to="/products/">
                                    <Icon.Grid size="18" />
                                    Products
                                </Link>
                            </li>
                            <li className={path === '/collaborations/' ? s.active : ''}>
                                <Link to="/collaborations/">
                                    <Icon.Users size="18" />
                                    Collaborations
                                </Link>
                            </li>
                            <li className={path === '/purchase-verify/' ? s.active : ''}>
                                <Link to="/purchase-verify/">
                                    <Icon.CheckCircle size="18" />
                                    Purchase Verify
                                </Link>
                            </li>
                            <li className={path === '/settings/' ? s.active : ''}>
                                <Link to="/settings/">
                                    <Icon.Sliders size="18" />
                                    Settings
                                </Link>
                            </li>
                        </ul>

                        <div className={s.pullRight}>
                            <a className={s.logout} href="/logout/" onClick={self.logoutClick}>
                                <Icon.LogOut size="18" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(s)(Navigation);
