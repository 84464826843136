
export default function loading(state = 0, action) {
    switch (action.type) {
    case 'STOP_LOADING':
        return 0;
    case 'START_LOADING':
        return 1;
    default:
        return state < 0 ? 0 : state;
    }
}
