import React from 'react';
import Products from './Products';
import Layout from '../../components/Layout';

export default {

    path: '/products',

    async action({ user }) {
        if (!user) {
            return false;
        }

        return {
            title: 'Products',
            component: <Layout><Products /></Layout>,
        };
    },

};
