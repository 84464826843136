import React from 'react';
import Home from './Home';
import Dashboard from './Dashboard';
import Layout from '../../components/Layout';

export default {

    path: '/',

    async action({ user }) {
        if (user) {
            return {
                title: 'Dashboard',
                component: <Layout><Dashboard /></Layout>,
            };
        }
        return {
            title: 'EnvatoStats',
            component: <Layout><Home /></Layout>,
        };
    },

};
